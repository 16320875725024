/* eslint-disable */
/** @typedef {import("axios").AxiosResponse} AxiosResponse */

import { client } from "..";
import { AppError } from "../../services/AppError";

/**
 * Trigger the email to admin
 * @param {string} email
 * @param {string} recaptchaToken
 * @return {Promise<AxiosResponse<any, any>>}
 */
export function sendForgotPasswordEmail(email, recaptchaToken) {
  return client.post(
    "open/admin/sendResetPasswordEmail",
    { email: email.toLocaleLowerCase(), recaptchaToken ,portal: 'admin' },
  ).catch((e) => {
    throw new AppError(
      AppError.USER_PASSWORD_RESET_FAILED,
      e?.response?.data?.body?.message || e.message);
  });
}

/**
 * Trigger the email to admin
 * @param {string} code
 * @param {string} email
 * @param {string} recaptchaToken
 * @return {Promise<AxiosResponse<any, any>>}
 */
export function verifyResetCode(code, email, recaptchaToken) {
  return client.post(
    "open/admin/verifyResetVerificationCode",
    { code, email, recaptchaToken ,portal: 'admin' },
  ).catch((e) => {
    throw new AppError(
      AppError.USER_PASSWORD_RESET_FAILED,
      e?.response?.data?.body?.message || e.message);
  });
}

/**
 * Change password with code
 * @param {string} code
 * @param {string} email
 * @param {string} password
 * @param {string} recaptchaToken
 * @return {Promise<AxiosResponse<any, any>>}
 */
export function changePassword(code, email, password, recaptchaToken) {
  return client.post(
    "open/admin/resetPasswordByVerificationCode",
    { code, email, password, recaptchaToken ,portal: 'admin' },
  ).catch((e) => {
    throw new AppError(
      AppError.USER_PASSWORD_RESET_FAILED,
      e?.response?.data?.body?.message || e.message);
  });
}

/**
 * add claim with email and claim id
 * @param {string} email
 * @param {string} claim
 * @return {Promise<AxiosResponse<any, any>>}
 */
export function addClaim(email, claim) {
  return client.post(
    "/claim",
    { email, claim },
  ).catch((e) => {
    throw new AppError(
      AppError.USER_CLAIM_SET_FAILED,
      e?.response?.data?.body?.message || e.message);
  });
}

export function deleteClaim(email, claim) {
  return client.delete(
    "/claim", {
    data: {
      email, claim
    }
  }
  ).catch((e) => {
    throw new AppError(
      AppError.USER_CLAIM_DELETE_FAILED,
      e?.response?.data?.body?.message || e.message);
  });
}

export function getContractsList() {
  return client.get(
    `/documents/desiweds/docusign/partner?order=desc&page=1&limit=300&orderby=created_at`,

  ).catch((e) => {
    throw new AppError(
      AppError.CONTRACT_FETCHING_FAILED,
      e?.response?.data?.body?.message || e.message);
  });
}

export async function revokeRefreshTokens(mainEventId, enabled) {
  return client.post(
      "/admin/revokeFirebaseAuth",
      { mainEventId, enabled }
  );
}